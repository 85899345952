<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Weighted Averages</h2>
      <p class="mb-4">
        An average assumes that all numbers in the set are equally as important (i.e. have the same
        weight). But what if we want to assign a greater weight to a specific quantity? Let's
        consider your final grade in this course. If we simply calculated an average of all of your
        assignment, test, and lab grades, this would not give the correct answer. This is because
        the final exam is more important (i.e. has a greater weight) than an individual assignment.
      </p>
      <p class="mb-4">
        So how do we calculate a weighted average? These are calculated much the same as an average
        but each value is scaled by a weight,
        <stemble-latex content="$\text{w}_{\text{i}}$" />:
      </p>
      <p class="pl-6 mb-5">
        <stemble-latex
          content="$\text{Weighted Average}= \displaystyle{\frac{\sum_i \text{w}_i \text{x}_i}{\sum_i \text{w}_i}}$"
        />
      </p>

      <p class="mb-4">
        Let's consider if you had the following grade breakdown with each of the associated weights:
      </p>

      <v-simple-table style="width: 350px" class="pl-8 mb-6">
        <thead>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\textbf{Grade Item}$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$\textbf{Grade}$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$\textbf{Weight}$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Assignments}$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$91$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$20$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Midterms}$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$53$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$30$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Final Exam}$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$65$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$50$" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-3">In this case, the weighted average would be calculated like:</p>

      <p class="pl-6 mb-6">
        <stemble-latex
          content="$\text{Weighted Average} = \displaystyle{\frac{91 \times 20 + 53 \times 30 + 65 \times 50}{20 + 30 + 50}}=$"
        />
        <stemble-latex content="$\displaystyle{\frac{6660}{100}}=66.60$" />
      </p>

      <p class="mb-5">
        We can see here that the weighted average is 66.6, whereas the unweighted average, or simply
        the average, would be 69.7. The weighted average is lower since the highest grade (the
        assignments grade) actually had the lowest weight (so it contributes the LEAST to the
        overall grade).
      </p>

      <p class="mb-5">a) Calculate the weighted average for the following set of data:</p>

      <v-simple-table style="width: 350px" class="pl-8 mb-6">
        <thead>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\textbf{Grade Item}$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$\textbf{Grade}$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$\textbf{Weight}$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Assignments}$" />
            </td>
            <td style="height: 30px">
              <number-value :value="avg1num1" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$10$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Midterm 1}$" />
            </td>
            <td style="height: 30px">
              <number-value :value="avg1num2" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$25$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Midterm 2}$" />
            </td>
            <td style="height: 30px">
              <number-value :value="avg1num3" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$25$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Final Exam}$" />
            </td>
            <td style="height: 30px">
              <number-value :value="avg1num4" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$40$" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <calculation-input
        v-model="inputs.wavg1"
        class="mb-6"
        :prepend-text="'$\\text{Weighted Average:}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-5">
        b) Calculate the new weighted average using the same grades but the different weights as
        shown below.
      </p>

      <v-simple-table style="width: 350px" class="pl-8 mb-6">
        <thead>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\textbf{Grade Item}$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$\textbf{Grade}$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$\textbf{Weight}$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Assignments}$" />
            </td>
            <td style="height: 30px">
              <number-value :value="avg1num1" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$5$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Midterm 1}$" />
            </td>
            <td style="height: 30px">
              <number-value :value="avg1num2" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$10$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Midterm 2}$" />
            </td>
            <td style="height: 30px">
              <number-value :value="avg1num3" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$10$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Final Exam}$" />
            </td>
            <td style="height: 30px">
              <number-value :value="avg1num4" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$75$" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <calculation-input
        v-model="inputs.wavg2"
        class="mb-6"
        :prepend-text="'$\\text{Weighted Average:}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-5">
        c) Note that there is no need for the weights to add up to 100. This is common when grading
        as numerical grades are usually expressed as percentages (i.e. out of 100). Calculate the
        weighted average for the list of data shown below.
      </p>

      <v-simple-table style="width: 350px" class="pl-8 mb-6">
        <thead>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\textbf{Grade Item}$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$\textbf{Grade}$" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$\textbf{Weight}$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Assignments}$" />
            </td>
            <td style="height: 30px">
              <number-value :value="avg1num1" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$2$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Midterm 1}$" />
            </td>
            <td style="height: 30px">
              <number-value :value="avg1num2" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$6$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Midterm 2}$" />
            </td>
            <td style="height: 30px">
              <number-value :value="avg1num3" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$6$" />
            </td>
          </tr>
          <tr>
            <td style="height: 30px">
              <stemble-latex content="$\text{Final Exam}$" />
            </td>
            <td style="height: 30px">
              <number-value :value="avg1num4" />
            </td>
            <td style="height: 30px">
              <stemble-latex content="$10$" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <calculation-input
        v-model="inputs.wavg3"
        class="mb-6"
        :prepend-text="'$\\text{Weighted Average:}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question348',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        wavg1: null,
        wavg2: null,
        wavg3: null,
      },
    };
  },
  computed: {
    avg1num1() {
      return this.taskState.getValueBySymbol('avg1num1').content;
    },
    avg1num2() {
      return this.taskState.getValueBySymbol('avg1num2').content;
    },
    avg1num3() {
      return this.taskState.getValueBySymbol('avg1num3').content;
    },
    avg1num4() {
      return this.taskState.getValueBySymbol('avg1num4').content;
    },
  },
};
</script>
